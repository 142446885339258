<template>
  <div class="bbb disease">
    <van-nav-bar title="请选择疾病" left-arrow @click-left="onClickLeft" />

    <div class="page-wrapper">
      <div class="search-wrap">
        <div class="left">
          <van-icon class="icon" name="search" />

          <!-- <input
            class="input"
            v-model="search_value"
            placeholder="请输入病症关键词"
            @input="loadList"
          /> -->
          <el-input  class="disease-input" v-model="search_value" @input="loadList" placeholder="请输入病症关键词"></el-input> 
          <!-- <el-input  class="input" v-model="search_value" @input="loadList" placeholder="请输入病症关键词"></el-input> -->
        </div>
        <!-- <img class="icon" src="@/assets/images/scan.png" /> -->
      </div>

      <div class="disease-list-wrap">
        <div class="disease-list-title">
          可能疾病：
          <div class="tags">
            <div
              v-for="tag in disease"
              :key="tag"
              class="tag"
              @click="diseaseCli(tag)"
            >
              {{ tag }}
            </div>
          </div>
        </div>

        <div v-if="tableData.length !== 0" class="list">
          <div
            v-for="(item, index) in tableData"
            :key="item.id"
            class="item"
            :class="index == 0 && 'un-line'"
            @click="group(item)"
          >
            <span>
              {{ item.title }}
            </span>

            <van-icon
              slot="right-icon"
              v-show="choice_change(item.id)"
              name="success"
              :size="16"
              color="#32ae57"
            />
          </div>
        </div>
        <div v-else>
          <van-nodata :figure="imgUrl[3]" title="暂无疾病信息" />
        </div>
      </div>

      <div v-if="tableData.length !== 0" class="selected-disease-wrap">
        已选择疾病：{{ choice.length == 0 ? '无' : '' }}

        <div
          v-for="tag in choice"
          :key="tag.disease"
          class="tag"
          @click="handleClose(tag)"
        >
          {{ tag.title }}
        </div>
      </div>

      <div class="submit-btn" v-if="tableData.length !== 0" @click="determine">选择完毕</div>
    </div>

    <template v-if="0">
      <div
        id="Interrogation"
        style="height: calc(100% - 262px)"
        class="time_slot"
      >
        <van-search
          v-model="search_value"
          @input="loadList"
          clearable
          placeholder="请输入病症关键词"
        />
        可能疾病：
        <el-tag v-for="tag in disease" :key="tag" @click="diseaseCli(tag)">
          {{ tag }}
        </el-tag>
        <div style="height: 10%" v-if="choice.length == 0">
          <el-alert
            title="请从下表中选择病症"
            type="warning"
            center
            :closable="false"
            show-icon
          >
          </el-alert>
        </div>
        <div class="margin_top diseaseList" v-if="tableData.length !== 0">
          <van-cell-group>
            <van-cell
              :title="item.title"
              v-for="(item, index) of tableData"
              clickable
              @click="group(item)"
            >
              <van-icon
                slot="right-icon"
                v-show="choice_change(item.id)"
                name="success"
                :size="16"
                color="#32ae57"
              />
            </van-cell>
          </van-cell-group>
        </div>
        <div v-else>
          <van-nodata :figure="imgUrl[3]" title="暂无疾病信息" />
        </div>
        <footer_ />
      </div>
      <div class="choice_disease">
        已选择疾病：{{ choice.length == 0 ? '无' : '' }}
        <el-tag
          v-for="tag in choice"
          :key="tag.disease"
          closable
          @close="handleClose(tag)"
        >
          {{ tag.title }}
        </el-tag>
      </div>
      <div class="padding_05 disease_btn">
        <van-button
          @click="determine"
          color="linear-gradient(to right, #02d3d9, #09e0c0)"
          type="primary"
          block
          >病症选择完毕</van-button
        >
      </div>
    </template>
  </div>
</template>
<script>
import footer_ from '../components/footer.vue';
import base from './api/base.js';
import mixin from '@/utils/mixin';
export default {
  mixins: [mixin],
  components: {
    footer_,
  },
  data() {
    return {
      tableData: [],
      backups: [],
      choice: [],
      search_value: '',
      disease: [],
    };
  },
  methods: {
    handleClose(item) {
      let index = this.choice.findIndex((t) => {
        return t.id == item.id;
      });
      this.choice.splice(index, 1);
    },
    group(item) {
      let ifstate = this.choice.findIndex((t) => {
        return t.id == item.id;
      });
      if (ifstate !== -1) {
        this.choice.splice(ifstate, 2);
      } else if (this.choice.length <= 4) {
        this.choice.push(item);
      } else {
        this.$message.error('最多可选择5个病种');
      }
    },
    choice_change(id) {
      return this.choice.find((item) => {
        return item.id == id;
      });
    },
    onClickLeft() {
      console.log(this.choice);
      let choice = [];
      this.choice.forEach((element) => {
        choice.push(element.title);
      });
      if (choice.length !== 0) {
        const h = this.$createElement;
        this.$msgbox({
          title: '提醒',
          message: h('p', { style: 'text-align: center' }, [
            h('span', null, '您当前选择疾病'),
            h('i', { style: 'font-weight: 800' }, choice.join(',')),
          ]),
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          beforeClose: (action, instance, done) => {
            if (action == 'confirm') {
              this.determine();
              done();
            } else {
              this.$router.back(-1);
              done();
            }
          },
        });
      } else {
        const h = this.$createElement;
        this.$msgbox({
          title: '提醒',
          message: h('p', { style: 'text-align: center' }, [
            h('span', null, '您当前暂未选择疾病'),
          ]),
          showCancelButton: true,
          confirmButtonText: '确定',
          beforeClose: (action, instance, done) => {
            if (action == 'confirm') {
              this.$router.back(-1);
            } else {
            }
          },
        });
      }
    },
    diseaseCli(item) {
      this.search_value = item;
      this.loadList();
    },
    loadList() {
      let params = {
        search_db: 'DRGDatabase',
        query_type: this.search_value == '' ? 'equal' : 'like',
        start: 0,
        length: 50,
      };

      let data = {
        query: {
          title: this.search_value == '' ? undefined : this.search_value,
        },
      };

      let promise = this.$api.article.query(params, data).then((res) => {
        this.tableData = res.data.result;
        this.backups = JSON.parse(JSON.stringify(res.data.result));
      });
    },
    determine() {
      if (this.$route.query.type == 'past') {
        sessionStorage.setItem('past', JSON.stringify(this.choice));
      } else if (this.$route.query.type == 'disease') {
        console.log(this.choice);
        sessionStorage.setItem('disease', JSON.stringify(this.choice));
      } else {
        sessionStorage.setItem('family', JSON.stringify(this.choice));
      }
      this.$router.back(-1);
    },
  },
  created() {
    this.loadList();
    this.Drugs = JSON.parse(sessionStorage.getItem('Drugs'));
    let arr = [];
    let promises = [];
    if (this.Drugs) {
      this.Drugs.forEach((item) => {
        let params = {
          search_db: 'DiseaseSymptomDrug',
          query_type: 'like',
        };

        let data = {
          query: {
            drug: item.name,
          },
        };

        let promise = this.$api.article.query(params, data).then((res) => {
          res.data.result.forEach((element) => {
            arr.push(element);
          });
        });

        promises.push(promise);
      });
    }
    if (sessionStorage.getItem(this.$route.query.type)) {
      this.choice = JSON.parse(sessionStorage.getItem(this.$route.query.type));
    }
    // 使用Promise.all等待所有异步请求完成
    Promise.all(promises).then(() => {
      let disease = [];
      arr.forEach((element) => {
        element.disease.split(',').forEach((item) => {
          if (disease.indexOf(item) == -1) {
            if (disease.length < 3) {
              disease.push(item);
            }
          }
        });
      });
      this.disease = disease;
    });
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.van-nav-bar__title {
  color: #111 !important;
  font-weight: 600 !important;
}

.bbb {
  background: url('~@/assets/images/page-bg-2.png') no-repeat;
  background-size: contain;
  background-color: #fff;
}

.page-wrapper {
  padding: 1.5rem 0.78rem;

  .search-wrap {
    display: flex;
    align-items: center;
    height: 2.09rem;

    .left {
      margin-right: 0.66rem;
      padding: 0 1rem;
      flex: 1;
      display: flex;
      align-items: center;
      height: 100%;
      background: #ffffff;
      border-radius: 1.05rem;
      border: 0.1rem solid #868686;
      .icon {
        margin-right: 0.5rem;
      }

      .input {
        border-width: 0;
        flex: 1;

        height: 1rem;
        font-size: 0.8rem;
      }
    }

    & > .icon {
      width: 1.34rem;
      height: 1.34rem;
    }
  }
}

.disease-list-wrap {
  margin-top: 1.22rem;

  font-family: Microsoft YaHei;
  font-weight: bold;
  font-size: 0.94rem;
  color: #171725;
  line-height: 1.5rem;

  flex: 1;

  .disease-list-title {
    display: flex;
    align-items: center;
    &::before {
      content: '#';
      margin-right: 0.28rem;
      width: 1.08rem;
      height: 1.06rem;
      background: #409eff;
      border-radius: 50% 50% 4px 50%;

      font-family: Microsoft YaHei;
      font-weight: bold;
      font-size: 0.94rem;
      color: #ffffff;
      line-height: 1.06rem;
      text-align: center;

      display: inline-block;
    }

    .tags {
      display: inline-flex;
      column-gap: 0.34rem;
      .tag {
        padding: 0 0.63rem;
        min-width: 2.81rem;
        height: 1.19rem;
        background: rgba(64, 158, 255, 0.08);
        border-radius: 0.31rem;
        border: 0.06rem solid #409eff;
        display: flex;
        align-items: center;
        justify-content: center;

        font-family: SourceHanSansCN;
        font-weight: 400;
        font-size: 0.81rem;
        color: #588fde;
        line-height: 2.03rem;
      }
    }
  }

  .list {
    margin-top: 0.69rem;
    padding: 0.25rem 0.72rem 0.25rem 0.84rem;
    background: #ffffff;
    box-shadow: 0rem 0rem 1rem 0rem rgba(135, 161, 216, 0.22);
    border-radius: 0.81rem;

    height: 25rem;
    overflow-y: scroll;

    display: flex;
    flex-direction: column;

    .item {
      padding-left: 0.78rem;
      height: 2.97rem;

      font-family: SourceHanSansCN;
      font-weight: 500;
      font-size: 0.94rem;
      color: #1b1b1b;
      line-height: 2.97rem;

      border-top: 0.06rem solid #e9ebed;

      display: flex;
      justify-content: space-between;

      span {
        &::before {
          content: '#';
          margin-right: 0.5rem;
        }
      }
      &.un-line {
        border-top-width: 0;
      }
    }
  }
}

.selected-disease-wrap {
  margin-top: 1.06rem;
  padding: 0 0 0 0.81rem;

  font-family: SourceHanSansCN;
  font-weight: 500;
  font-size: 0.94rem;
  color: #000000;
  line-height: 1.38rem;

  .tag {
    position: relative;
    padding: 0 1.16rem;
    margin: 0 0.5rem 0.5rem 0;
    background: rgba(64, 158, 255, 0.08);
    border-radius: 0.31rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    font-family: SourceHanSansCN;
    font-weight: 400;
    font-size: 0.88rem;
    color: #5382ec;

    height: 2.41rem;
    background: #e3effd;
    border-radius: 0.34rem;

    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;

      width: 1.78rem;
      height: 1.25rem;

      background-color: #588fde;
      clip-path: polygon(100% 0, 0% 100%, 100% 100%);
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0.04rem;
      right: 0.3rem;

      width: 0.48rem;
      height: 0.96rem;

      border-color: #fff;
      border-style: solid;
      border-width: 0 0.18rem 0.18rem 0;
      transform: rotate(45deg) scale(0.6);
    }
  }
}

.submit-btn {
  width: 100%;
  height: 3.16rem;
  background: #409eff;
  border-radius: 0.42rem;

  display: flex;
  align-items: center;
  justify-content: center;
  font-family: SourceHanSansCN;
  font-weight: 500;
  font-size: 0.94rem;
  color: #ffffff;
  line-height: 1.38rem;
}
</style>
<style lang="less">
.disease-input{
  .el-input__inner{
    all: initial;
  }
}
</style>
